import Button from "@mui/material/Button";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import backEndIcon from "../components/assets/images/navbar/backend.webp";
import databaseEndIcon from "../components/assets/images/navbar/database.webp";
import frontEndIcon from "../components/assets/images/navbar/frontend.webp";
import left_icon from "../components/assets/images/navbar/left.webp";
import mobileEndIcon from "../components/assets/images/navbar/mobile.webp";
import hash_site_logo from "./assets/images/home/hashtag-logo.svg";

const navbar = () => {
  return (
    <header className="navigation fixed-top">
      <nav className="navbar navbar-expand-lg navbar-dark p-0">
        <NavLink className="navbar-brand font-tertiary" to="/">
          <img
            src={hash_site_logo}
            alt="Hashtag Infosys"
            className="site-logo"
          />
        </NavLink>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle na`vigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navigation">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ">
              <NavLink className="nav-link" aria-current="page" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                About
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Service
                {/* <img
                  style={{ width: "10px" }}
                  className="ml-2 mb-1"
                  src={DownArrow}
                  alt=""
                /> */}
              </Link>
              <ul
                className="dropdown-menu dm_width"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <ul
                    className="dropdwn-sub-item"
                    style={{ marginLeft: "8px", marginTop: "0px" }}
                  >
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/web-development-company"
                      >
                        Web Development
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/mobile-development-company"
                      >
                        Mobile Development
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/custom-software-company"
                      >
                        Custom Software
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/software-testing-company"
                      >
                        QA & Testing
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/staff-augmentation-company"
                      >
                        Staff Augmentation
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/support-maintenance-company"
                      >
                        Support & Maintenance
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/cloud-transformation-company"
                      >
                        Cloud
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/devops-services-company"
                      >
                        DevOps
                      </NavLink>
                    </h5>
                    <h5 style={{ marginBottom: "12px" }}>
                      <img
                        style={{ width: "7px", marginRight: "8px" }}
                        src={left_icon}
                        alt=""
                      />
                      <NavLink
                        className="field"
                        to="/services/machine-learning-company"
                      >
                        Artificial Intelligence
                      </NavLink>
                    </h5>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown dropdown-col-2">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Technology
                {/* <img
                  style={{ width: "10px" }}
                  className="ml-2 mb-1"
                  src={DownArrow}
                  alt=""
                /> */}
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <img
                    style={{ width: "25px", marginRight: "12px" }}
                    src={frontEndIcon}
                    alt=""
                  />
                  <span className="dropdown-item-title">Front End</span>
                  <ul className="dropdwn-sub-item">
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/reactjs-framework"
                      >
                        React JS
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/vuejs-framework"
                      >
                        Vue JS
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/angularjs-framework"
                      >
                        Angular JS
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/html5-technology"
                      >
                        HTML5
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/javascript-technology"
                      >
                        Javascript
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/css3-technology"
                      >
                        CSS3
                      </NavLink>
                    </h5>
                  </ul>
                </li>
                <li className="backend-ch">
                  <img
                    style={{ width: "25px", marginRight: "12px" }}
                    src={backEndIcon}
                    alt=""
                  />
                  <span className="dropdown-item-title">Back End</span>
                  <ul className="dropdwn-sub-item">
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/nodejs-framework"
                      >
                        Node JS
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/laravel-framework"
                      >
                        Laravel
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/dot-net-development"
                      >
                        .NET
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink className="field" to="/technology/php-framework">
                        PHP
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/codeigniter-framework"
                      >
                        Codeigniter
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/java-framework"
                      >
                        Java
                      </NavLink>
                    </h5>
                  </ul>
                </li>
                <li>
                  <img
                    style={{ width: "23px", marginRight: "12px" }}
                    src={mobileEndIcon}
                    alt=""
                  />
                  <span className="dropdown-item-title">Mobile</span>
                  <ul className="dropdwn-sub-item">
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/android-development"
                      >
                        Android
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/ios-development"
                      >
                        iOS
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/flutter-development"
                      >
                        Flutter
                      </NavLink>
                    </h5>
                  </ul>
                </li>
                <li className="bottom-database">
                  <img
                    style={{ width: "25px", marginRight: "12px" }}
                    src={databaseEndIcon}
                    alt=""
                  />
                  <span className="dropdown-item-title">Database</span>
                  <ul className="dropdwn-sub-item">
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/mongodb-database"
                      >
                        MonoDB
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/postgresql-database"
                      >
                        PostgreSQL
                      </NavLink>
                    </h5>
                    <h5>
                      <NavLink
                        className="field"
                        to="/technology/mysql-database"
                      >
                        MySQL
                      </NavLink>
                    </h5>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/portfolio">
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/blog">
                Blog
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/career">
                Career
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/lifeskyline">
                Life @ Hashtag
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/partnership">
                Partnership
              </NavLink>
            </li>
          </ul>
          <Button
            className="get-quote-btn"
            size="small"
            style={{
              left: "12px",
              background: "#0785c6",
              height: "38px",
              borderRadius: "35px",
              borderBottom: "none",
            }}
          >
            <NavLink
              className="nav-link "
              to="/contact"
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              Get Quote
            </NavLink>
          </Button>
        </div>
      </nav>
    </header>
  );
};

export default navbar;
