import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import { TRACKING_ID } from "./config";

import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import DemandProgramingPage from "./components/Blog/Page/demand_programing_page.js";

const Home = lazy(() => import("./components/Home/Home"));
const Career = lazy(() => import("./components/Career/Career"));
const Blog = lazy(() => import("./components/Blog/Blog"));
const LifeSkyline = lazy(() => import("./components/LifeSkyline/LifeSkyline"));
const About = lazy(() => import("./components/About/About"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Portfolio = lazy(() => import("./components/Portfolio/Portfolio"));
const MobileApplicationDevelopment = lazy(() =>
  import(
    "./components/Services/components/MobileAppDev/MobileApplicationDevelopment"
  )
);
const TechnologyStack = lazy(() => import("./components/Home/TechnologyStack"));
const ReactJs = lazy(() => import("./components/Techonology/frontEnd/ReactJS"));
const AngularJS = lazy(() =>
  import("./components/Techonology/frontEnd/AngularJS")
);
const CSS3 = lazy(() => import("./components/Techonology/frontEnd/CSS3"));
const Javascript = lazy(() =>
  import("./components/Techonology/frontEnd/Javascript")
);
const VueJS = lazy(() => import("./components/Techonology/frontEnd/VueJS"));
const HTML5 = lazy(() => import("./components/Techonology/frontEnd/HTML5"));
const Codeigniter = lazy(() =>
  import("./components/Techonology/backEnd/Codeigniter")
);
const Java = lazy(() => import("./components/Techonology/backEnd/Java"));
const PHP = lazy(() => import("./components/Techonology/backEnd/PHP"));
const NodeJs = lazy(() => import("./components/Techonology/backEnd/NodeJS"));
const Laravel = lazy(() => import("./components/Techonology/backEnd/laravel"));
const DotNet = lazy(() => import("./components/Techonology/backEnd/dotNET"));
const MongoDB = lazy(() => import("./components/Techonology/dataBase/mongoDB"));
const MySQL = lazy(() => import("./components/Techonology/dataBase/mySQL"));
const PostgreSQL = lazy(() =>
  import("./components/Techonology/dataBase/postgreSQL")
);
const Android = lazy(() => import("./components/Techonology/mobile/Android"));
const Flutter = lazy(() => import("./components/Techonology/mobile/Flutter"));
const IOS = lazy(() => import("./components/Techonology/mobile/iOS"));
const ReactVsAngularPage = lazy(() =>
  import("./components/Blog/Page/react_vs_angular_page.js")
);
const PhpAdvantagesPage = lazy(() =>
  import("./components/Blog/Page/php_advantages_page.js")
);
const JavaKotlinPage = lazy(() =>
  import("./components/Blog/Page/java_vs_kotlin_page.js")
);
const PhpFramworkPage = lazy(() =>
  import("./components/Blog/Page/php_framwork_page.js")
);
const CodingVsProgramingPage = lazy(() =>
  import("./components/Blog/Page/coding_vs_programing_page.js")
);
const ReactVsFlutterPage = lazy(() =>
  import("./components/Blog/Page/react_vs_flutter_page.js")
);
const WebdesignDevelopmentPage = lazy(() =>
  import("./components/Blog/Page/webdesign_development_page.js")
);
const PageNotFound = lazy(() => import("./components/404/PageNotFound"));
const WebDevelopment = lazy(() =>
  import("./components/Services/components/WebDev/WebDevelopment")
);
const CustomSoftware = lazy(() =>
  import("./components/Services/components/CustomSoft/CustomSoftware")
);
const QaTesting = lazy(() =>
  import("./components/Services/components/QaTesting/QaTesting")
);
const SupportMaintenance = lazy(() =>
  import(
    "./components/Services/components/SupportMaintenance/SupportMaintenance"
  )
);
const StaffAugmentation = lazy(() =>
  import("./components/Services/components/StaffAugmentation/StaffAugmentation")
);
const Cloud = lazy(() =>
  import("./components/Services/components/Cloud/Cloud")
);
const DevOps = lazy(() =>
  import("./components/Services/components/DevOps/DevOps")
);
const ArtificialIMachineLearning = lazy(() =>
  import(
    "./components/Services/components/ArtificialIMachineLearning/ArtificialIMachineLearning"
  )
);
const Partnership = lazy(() => import("./components/Partnership/Partnership"));

ReactGA.initialize(TRACKING_ID);

function App() {
  //google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<></>}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/lifeskyline" element={<LifeSkyline />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/partnership" element={<Partnership />} />
            <Route
              exact
              path="/technologystack"
              element={<TechnologyStack />}
            />

            {/* technology frontend */}

            <Route
              exact
              path="/technology/reactjs-framework"
              element={<ReactJs />}
            />
            <Route
              exact
              path="/technology/angularjs-framework"
              element={<AngularJS />}
            />
            <Route
              exact
              path="/technology/vuejs-framework"
              element={<VueJS />}
            />
            <Route
              exact
              path="/technology/css3-technology"
              element={<CSS3 />}
            />
            <Route
              exact
              path="/technology/html5-technology"
              element={<HTML5 />}
            />
            <Route
              exact
              path="/technology/javascript-technology"
              element={<Javascript />}
            />

            {/* technology backend */}

            <Route
              exact
              path="/technology/dot-net-development"
              element={<DotNet />}
            />
            <Route
              exact
              path="/technology/codeigniter-framework"
              element={<Codeigniter />}
            />
            <Route exact path="/technology/java-framework" element={<Java />} />
            <Route
              exact
              path="/technology/laravel-framework"
              element={<Laravel />}
            />
            <Route
              exact
              path="/technology/nodejs-framework"
              element={<NodeJs />}
            />
            <Route exact path="/technology/php-framework" element={<PHP />} />

            {/* technology database */}

            <Route
              exact
              path="/technology/mongodb-database"
              element={<MongoDB />}
            />
            <Route
              exact
              path="/technology/postgresql-database"
              element={<PostgreSQL />}
            />
            <Route
              exact
              path="/technology/mysql-database"
              element={<MySQL />}
            />

            {/* technology mobile */}

            <Route
              exact
              path="/technology/android-development"
              element={<Android />}
            />
            <Route
              exact
              path="/technology/flutter-development"
              element={<Flutter />}
            />
            <Route exact path="/technology/ios-development" element={<IOS />} />

            {/* service */}

            <Route
              exact
              path="/services/mobile-development-company"
              element={<MobileApplicationDevelopment />}
            />
            <Route
              exact
              path="/services/web-development-company"
              element={<WebDevelopment />}
            />
            <Route
              exact
              path="/services/custom-software-company"
              element={<CustomSoftware />}
            />
            <Route
              exact
              path="/services/software-testing-company"
              element={<QaTesting />}
            />
            <Route
              exact
              path="/services/support-maintenance-company"
              element={<SupportMaintenance />}
            />
            <Route
              exact
              path="/services/staff-augmentation-company"
              element={<StaffAugmentation />}
            />
            <Route
              exact
              path="/services/cloud-transformation-company"
              element={<Cloud />}
            />
            <Route
              exact
              path="/services/devops-services-company"
              element={<DevOps />}
            />
            <Route
              exact
              path="/services/machine-learning-company"
              element={<ArtificialIMachineLearning />}
            />
            <Route
              exact
              path="/react-vs-angular"
              element={<ReactVsAngularPage />}
            />
            <Route
              exact
              path="/php_advantages"
              element={<PhpAdvantagesPage />}
            />
            <Route exact path="/java-vs-kotlin" element={<JavaKotlinPage />} />
            <Route
              exact
              path="/know-about-php-frameworks"
              element={<PhpFramworkPage />}
            />
            <Route
              exact
              path="/coding-vs-programing"
              element={<CodingVsProgramingPage />}
            />
            <Route
              exact
              path="/react-vs-flutter"
              element={<ReactVsFlutterPage />}
            />
            <Route
              exact
              path="/web-design-development"
              element={<WebdesignDevelopmentPage />}
            />
            <Route
              exact
              path="/programming-in-demand"
              element={<DemandProgramingPage />}
            />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>

      <script src="https://www.skylineinfosys.com/assets/js/typed.min.js"></script>
    </>
  );
}

export default App;
